import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/Layout'
import Helmet from 'react-helmet'

export default () => (
  <Layout>
    <main className="l-main">
      <div className="l-container">
        <Helmet titleTemplate="%s | 株式会社アーバ">
          <title>送信完了</title>
          <meta
            name="description"
            content="送信ありがとうございました。"
          />
        </Helmet>
        <h1 className="c-pageTitle" data-spacingsp="mb7">
          <span className="c-pageTitle__container">
            <span className="c-pageTitle__textJa">お問い合わせ</span>
            <span className="c-pageTitle__textEn">CONTACT</span>
          </span>
        </h1>
      </div>
      <div className="l-container--wide">
        <ul className="p-breadCrumbs">
          <li className="p-breadCrumbs__item"><Link to="/" className="p-breadCrumbs__link">ホーム</Link></li>
          <li className="p-breadCrumbs__item isCurrent">お問い合わせ</li>
        </ul>
      </div>
      <div className="l-container">
        <div className="p-contact--thanks">
          <div className="u-alignCenter u-alignSpLeft u-lineHeight200" data-spacing="mb8">
            <p data-spacing="mb2">
              この度はエントリーいただきありがとうございます。
            </p>
            <p data-spacing="mb2">
              折り返し、担当者からメールにてご連絡差し上げます。
            </p>
            <p data-spacing="mb2">
              ※メールで頂いたお問い合わせへの回答にはお時間を頂戴する場合もございますので、<br className="u-mqHidden--sm"/>
              あらかじめご了承くださいませ。
            </p>
          </div>
          <div className="u-alignCenter" data-spacing="pt8 pb8 mt8 mb8">
            <Link to="/" className="c-button--skewOrange p-contact__submit">
              トップへ戻る
            </Link>
          </div>
        </div>
      </div>
    </main>
  </Layout>
)
